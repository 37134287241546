import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$store.state.output == 'nahal'?'nahal-output':'salam-output'},[(_vm.loading)?_c('div',{attrs:{"transition":"fade-transition","id":"loading"}},[(_vm.$store.state.output == 'nahal')?_c(VImg,{staticClass:"me-3",attrs:{"src":"img/logo1.svg","max-height":"80px","max-width":"300px","alt":"logo","contain":""}}):_vm._e(),(_vm.$store.state.output == 'salam')?_c(VImg,{staticClass:"me-3 mb-5",attrs:{"src":"img/logo2.svg","max-height":"150px","max-width":"300px","alt":"logo","contain":""}}):_vm._e(),_c('br'),(_vm.responsed != -1)?_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":(_vm.$store.state.output=='nahal')?'#be3190':'#00394c'}}):_vm._e(),_c('br'),(_vm.responsed == -1)?_c(VAlert,{staticClass:"mt-5",attrs:{"prominent":"","rounded":"","color":"#ff0000"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow",staticStyle:{"color":"white"}},[_vm._v(" خطا در برقراری ارتباط با سرور ")]),_c(VCol,{staticClass:"shrink"},[_c(VIcon,{staticStyle:{"color":"white"}},[_vm._v("mdi-school")])],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.responsed = 1)?_c(_vm.resolveLayout,{tag:"component"},[_c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.$store.state.exit),callback:function ($$v) {_vm.$set(_vm.$store.state, "exit", $$v)},expression:"$store.state.exit"}},[_c(VCard,[_c('h4',{staticStyle:{"padding":"15px"}},[_vm._v("خروج از سیستم ؟")]),_c(VCardText),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#eee"},on:{"click":function($event){_vm.$store.state.exit = false}}},[_vm._v(" خیر ")]),_c(VBtn,{attrs:{"color":(_vm.$store.state.output=='nahal')?'#be3190':'#00394c',"dark":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" بله ")])],1)],1)],1),_c('router-view')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }