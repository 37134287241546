import { render, staticRenderFns } from "./blank.vue?vue&type=template&id=2b4b3a4f"
var script = {}


/* normalize component */
import normalizer from "!C:\\Users\\Mostafa Adibi\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports