import { render, staticRenderFns } from "./NavMenuSectionTitle.vue?vue&type=template&id=e48aa918&scoped=true"
import script from "./NavMenuSectionTitle.vue?vue&type=script&lang=js"
export * from "./NavMenuSectionTitle.vue?vue&type=script&lang=js"
import style0 from "./NavMenuSectionTitle.vue?vue&type=style&index=0&id=e48aa918&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!C:\\Users\\Mostafa Adibi\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e48aa918",
  null
  
)

export default component.exports