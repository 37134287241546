import Vue from "vue";

Vue.mixin({
  methods: {
    async api(url, datas) {
      if (this.$cookie.get("session")) {
        const res = await this.$http({ method: "post", url: this.$store.state.api_url + url, headers: { "Content-Type": "application/json", Authorization: "Bearer " + this.$cookie.get("session") }, data: JSON.stringify(datas) });
        return await res;
      } else {
        const res = await this.$http({ method: "post", url: this.$store.state.api_url + url, headers: { "Content-Type": "application/json" }, data: JSON.stringify(datas) });
        return await res;
      }
    },
    async api_get(url, datas) {
      if (this.$cookie.get("session")) {
        const res = await this.$http({ method: "get", url: this.$store.state.api_url + url, headers: { "Content-Type": "application/json", Authorization: "Bearer " + this.$cookie.get("session") } });
        return await res;
      } else {
        const res = await this.$http({ method: "get", url: this.$store.state.api_url + url, headers: { "Content-Type": "application/json" } });
        return await res;
      }
    },
  },
});
