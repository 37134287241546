import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import preset from "./default-preset/preset";
import axios from "axios";
import VueAxios from "vue-axios";
//Cropper
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd.min";
import "vuejs-clipper/dist/vuejs-clipper.css";
var VueCookie = require("vue-cookie");

import VuetifyMoney from "vuetify-money";
Vue.use(VuetifyMoney);

Vue.use(VueCookie);
Vue.use(Vuetify);
Vue.use(VueRx);
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
  },
});

Vue.use(VueAxios, axios);
export default new Vuetify({
  rtl: true,
  preset,
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
});
