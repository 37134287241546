<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <!-- d-block d-lg-none me-2 -->
          <v-app-bar-nav-icon
            
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-text-field v-if="false"
            rounded
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

   
          <theme-switcher></theme-switcher>
          
          

<div v-if="$store.state.notifications" style="position:relative">
          <v-btn  to="/notifications"
          :class="$store.state.new_notifications.length > 0?'animate':''"
            icon
            small
            class="ms-3 bell"
          >
        
            <v-icon>
              mdi-bell-outline
            </v-icon>
          </v-btn>
            <span v-if="$store.state.new_notifications.length > 0"  class="badget">{{$store.state.new_notifications.length}}</span>
          </div>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main style="padding-bottom:150px;">
      <div class="app-content-container boxed-container pa-6">
          
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6  d-flex justify-space-between" >
          <!-- <span>
            <a style="margin-top:50px"
              href="https://nahalgasht.com"
              class="text-decoration-none"
              target="_blank"
            >نهال گشت </a> &copy; 2021</span> -->
          <span v-if="false" class="d-sm-inline d-none">
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Freebies</a>
            <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >MIT Licence</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>

import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

  export default {
    name: 'Dashboard',
      components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
    data: () => ({
isDrawerOpen : false

    }),
  }
</script>
