require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#be3190',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#be3190',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },


      // light: {
      //   primary: '#00394c',
      //   accent: '#0d6efd',
      //   secondary: '#8A8D93',
      //   success: '#56CA00',
      //   info: '#16B1FF',
      //   warning: '#FFB400',
      //   error: '#FF4C51',
      // },
      // dark: {
      //   primary: '#bd1f4f',
      //   accent: '#0d6efd',
      //   secondary: '#8A8D93',
      //   success: '#56CA00',
      //   info: '#16B1FF',
      //   warning: '#FFB400',
      //   error: '#FF4C51',
      // },

    },
  },
}
