import { render, staticRenderFns } from "./ThemeSwitcher.vue?vue&type=template&id=3dd0b86b"
import script from "./ThemeSwitcher.vue?vue&type=script&lang=js"
export * from "./ThemeSwitcher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!C:\\Users\\Mostafa Adibi\\AppData\\Roaming\\npm\\node_modules\\@vue\\cli-service\\node_modules\\@vue\\vue-loader-v15\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports