<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="$vuetify.theme.dark = !$vuetify.theme.dark;set()"
    >
      {{ $vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-fade-transition>
</template>
<script>

  export default {
    data: () => ({


    }),
    methods:{
      set(){

        this.$cookie.set('theme', this.$vuetify.theme.dark, 1000);
      }
    }
  }
</script>

