<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img v-if="$store.state.output == 'nahal'" src="img/logo1.svg" max-height="80px" max-width="150px" alt="logo"
          class="app-logo me-3"></v-img>
        <v-img v-if="$store.state.output == 'salam'" src="img/logo2.svg" max-height="80px" max-width="150px" alt="logo"
          class="app-logo me-3"></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="لیست تورها" :to="{ name: 'tours' }" icon="mdi-bag-personal-outline"></nav-menu-link>


      <nav-menu-group title="هتل ها" icon="mdi-format-list-bulleted-square">

        <nav-menu-link title="لیست هتل ها" :to="{ name: 'hotels' }"
          icon="mdi-format-list-bulleted-square"></nav-menu-link>

          <nav-menu-link v-if="($store.state.output == 'nahal' && $store.state.nahal_access.includes($store.state.user_object.user_id))" title="لیست هتل ها + برگه ها" :to="{ name: 'hotels-data' }"
          icon="mdi-format-list-bulleted-square"></nav-menu-link>

        <nav-menu-link title="هتل جدید" :to="{ name: 'hotel_new' }" icon="mdi-office-building-outline"></nav-menu-link>

      </nav-menu-group>

      <nav-menu-link v-if="($store.state.output == 'nahal' && ['1','2','824','881'].includes($store.state.user_object.user_id))" title="رزرو" :to="{ name: 'reserve' }" icon="mdi-web-plus"></nav-menu-link>

      <nav-menu-link title="پرواز  " :to="{ name: 'flights' }" icon="mdi-airplane"></nav-menu-link>

      <nav-menu-link v-if="false" title="قطار  " :to="{ name: 'trains' }" icon="mdi-train"></nav-menu-link>

      <nav-menu-link v-if="false" title="اتوبوس  " :to="{ name: 'bus' }" icon="mdi-bus"></nav-menu-link>

      <nav-menu-link title="ویزا" :to="{ name: 'visa' }" icon="mdi-smart-card-outline"></nav-menu-link>

      <nav-menu-link v-if="false" title="مشاوره آنلاین   " :to="{ name: 'consulting' }"
        icon="mdi-train"></nav-menu-link>


      <nav-menu-link title=" اعلان ها   " :to="{ name: 'notifications' }" icon="mdi-bell"></nav-menu-link>

      <nav-menu-link title=" تنظیمات " :to="{ name: 'settings' }" icon="mdi-cog-outline"></nav-menu-link>
      <nav-menu-section-title v-if="false" title="USER INTERFACE"></nav-menu-section-title>

    </v-list>

  </v-navigation-drawer>
</template>

<script>

import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.bg-gradient-primary {
  background: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
